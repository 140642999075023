import React, { useState, useRef, useEffect } from 'react';
import { Form, Input, Button, message } from 'antd';
import { useNavigate } from 'react-router-dom';
import './style.less';

const Login: React.FC = () => {
  const [captcha, setCaptcha] = useState('');
  const captchaCanvasRef = useRef<HTMLCanvasElement | null>(null);
  const navigate = useNavigate();

  const generateCaptcha = () => {
    const chars = 'ABCDEFGHJKLMNPQRSTUVWXYZ23456789'; // 去掉容易混淆的字符
    let result = '';
    for (let i = 0; i < 4; i++) {
      result += chars.charAt(Math.floor(Math.random() * chars.length));
    }
    setCaptcha(result);
    return result;
  };

  const drawCaptcha = (text: string) => {
    const canvas = captchaCanvasRef.current;
    if (canvas) {
      const ctx = canvas.getContext('2d');
      if (ctx) {
        ctx.clearRect(0, 0, canvas.width, canvas.height);
        ctx.font = '24px Arial';
        ctx.fillStyle = '#000';
        ctx.textBaseline = 'middle';
        ctx.textAlign = 'center';

        // 绘制倾斜的字母
        for (let i = 0; i < text.length; i++) {
          const x = 20 + i * 20;
          const y = 20;
          const angle = Math.random() * 0.5 - 0.25; // 随机倾斜角度
          ctx.save();
          ctx.translate(x, y);
          ctx.rotate(angle);
          ctx.fillText(text[i], 0, 0);
          ctx.restore();
        }

        // 添加随机线条
        for (let i = 0; i < 5; i++) {
          ctx.strokeStyle = `rgba(0,0,0,${Math.random()})`;
          ctx.beginPath();
          ctx.moveTo(Math.random() * canvas.width, Math.random() * canvas.height);
          ctx.lineTo(Math.random() * canvas.width, Math.random() * canvas.height);
          ctx.stroke();
        }
      }
    }
  };

  useEffect(() => {
    const newCaptcha = generateCaptcha();
    drawCaptcha(newCaptcha);
  }, []);

  const handleLogin = (values: any) => {
    if (values.captcha.toUpperCase() !== captcha) {
      message.error('验证码错误，请重试！');
      const newCaptcha = generateCaptcha();
      drawCaptcha(newCaptcha);
      return;
    }
    console.log('Login values:', values);
    message.success('登录成功！');
    navigate('/projects');
  };

  return (
    <div className="login-page">
      <div className="login-container">
        <h2>登录</h2>
        <Form
          name="login"
          initialValues={{ remember: true }}
          onFinish={handleLogin}
        >
          <Form.Item
            name="username"
            rules={[{ required: true, message: '请输入用户名!' }]}
          >
            <Input placeholder="用户名" />
          </Form.Item>

          <Form.Item
            name="password"
            rules={[{ required: true, message: '请输入密码!' }]}
          >
            <Input.Password placeholder="密码" />
          </Form.Item>

          <Form.Item
            name="captcha"
            rules={[{ required: true, message: '请输入验证码!' }]}
          >
            <Input
              placeholder="验证码"
              addonAfter={
                <canvas
                  ref={captchaCanvasRef}
                  width="100"
                  height="40"
                  style={{ cursor: 'pointer', userSelect: 'none' }}
                  onClick={() => {
                    const newCaptcha = generateCaptcha();
                    drawCaptcha(newCaptcha);
                  }}
                />
              }
            />
          </Form.Item>

          <Form.Item>
            <Button type="primary" htmlType="submit" block>
              登录
            </Button>
          </Form.Item>
        </Form>
      </div>
    </div>
  );
};

export default Login; 