import React, { useState } from 'react';
import { Layout, Tree, Space, Button, Input, Modal, Form, Select, Slider, message, Card } from 'antd';
import { FolderOutlined, FileOutlined, PlusOutlined, ArrowLeftOutlined, EditOutlined, CloseOutlined, DeleteOutlined } from '@ant-design/icons';
import { useNavigate } from 'react-router-dom';
import { Resizable } from 'react-resizable';
import type { Key } from 'antd/es/table/interface';
import type { DataNode } from 'antd/es/tree';
import 'react-resizable/css/styles.css';
import './style.less';
import ModelViewer from '../../components/ModelViewer';
import MiniModelViewer from '../../components/MiniModelViewer';

const { Header, Content } = Layout;
const { Option } = Select;

interface ComponentNode {
  title: string;
  key: string;
  children?: ComponentNode[];
  icon?: React.ReactNode;
  isLeaf?: boolean;
}

interface GeometryNode {
  title: string;
  key: string;
  children?: GeometryNode[];
  icon?: React.ReactNode;
  isLeaf?: boolean;
}

const ComponentDetail: React.FC = () => {
  const navigate = useNavigate();
  const [selectedComponent, setSelectedComponent] = useState<string>();
  const [newComponentModalVisible, setNewComponentModalVisible] = useState(false);
  const [form] = Form.useForm();
  const [siderWidth, setSiderWidth] = useState(300);
  const [selectedCategory, setSelectedCategory] = useState<string>('');
  const [categoryHeight, setCategoryHeight] = useState(300);
  const [showGeometryPanel, setShowGeometryPanel] = useState(false);
  const [originalSiderWidth, setOriginalSiderWidth] = useState(300);

  // 设置默认展开的节点
  const defaultExpandedKeys = ['bridge', 'tunnel', 'bridge-upper', 'bridge-lower', 'tunnel-lining', 'tunnel-waterproof'];
  const [expandedKeys, setExpandedKeys] = useState<Key[]>(defaultExpandedKeys);

  // 构件数据
  const componentData: ComponentNode[] = [
    {
      title: '桥梁构件',
      key: 'bridge',
      icon: <FolderOutlined />,
      children: [
        {
          title: '上部结构',
          key: 'bridge-upper',
          children: [
            { title: '箱梁', key: 'box-girder', icon: <FileOutlined />, isLeaf: true },
            { title: 'T梁', key: 't-girder', icon: <FileOutlined />, isLeaf: true },
          ]
        },
        {
          title: '下部结构',
          key: 'bridge-lower',
          children: [
            { title: '桥墩', key: 'pier', icon: <FileOutlined />, isLeaf: true },
            { title: '桥台', key: 'abutment', icon: <FileOutlined />, isLeaf: true },
          ]
        },
      ]
    },
    {
      title: '隧道构件',
      key: 'tunnel',
      icon: <FolderOutlined />,
      children: [
        {
          title: '衬砌结构',
          key: 'tunnel-lining',
          children: [
            { title: '标准衬砌', key: 'standard-lining', icon: <FileOutlined />, isLeaf: true },
          ]
        },
        {
          title: '防水结构',
          key: 'tunnel-waterproof',
          children: [
            { title: '防水板', key: 'waterproof-board', icon: <FileOutlined />, isLeaf: true },
          ]
        },
      ]
    }
  ];

  // 添加示例构件数据
  const exampleComponents: Record<string, ComponentNode[]> = {
    'bridge': [
      { 
        title: '标准箱梁-示例', 
        key: 'example-box-girder', 
        icon: <FileOutlined />, 
        isLeaf: true 
      },
      { 
        title: '连续箱梁-示例', 
        key: 'example-continuous-girder', 
        icon: <FileOutlined />, 
        isLeaf: true 
      }
    ],
    'tunnel': [
      { 
        title: '隧道衬砌-示例', 
        key: 'example-tunnel-lining', 
        icon: <FileOutlined />, 
        isLeaf: true 
      },
      { 
        title: '洞门-示例', 
        key: 'example-tunnel-portal', 
        icon: <FileOutlined />, 
        isLeaf: true 
      }
    ],
    'bridge-upper': [
      { 
        title: '箱梁横断面-示例', 
        key: 'example-box-section', 
        icon: <FileOutlined />, 
        isLeaf: true 
      },
      { 
        title: '箱梁纵断面-示例', 
        key: 'example-girder-elevation', 
        icon: <FileOutlined />, 
        isLeaf: true 
      }
    ],
    'bridge-lower': [
      { 
        title: '桥墩-示例', 
        key: 'example-pier-standard', 
        icon: <FileOutlined />, 
        isLeaf: true 
      },
      { 
        title: '桥台-示例', 
        key: 'example-abutment-standard', 
        icon: <FileOutlined />, 
        isLeaf: true 
      }
    ],
    'box-girder': [
      { 
        title: '单箱单室箱梁', 
        key: 'single-box-girder', 
        icon: <FileOutlined />, 
        isLeaf: true 
      },
      { 
        title: '单箱双室箱梁', 
        key: 'double-box-girder', 
        icon: <FileOutlined />, 
        isLeaf: true 
      },
      { 
        title: '变截面箱梁', 
        key: 'variable-box-girder', 
        icon: <FileOutlined />, 
        isLeaf: true 
      },
      { 
        title: '连续箱梁', 
        key: 'continuous-box-girder', 
        icon: <FileOutlined />, 
        isLeaf: true 
      }
    ]
  };

  // 添加几何信息数据
  const geometryData: GeometryNode[] = [
    {
      title: '几何信息',
      key: 'geometry',
      icon: <FolderOutlined />,
      children: [
        {
          title: '截面信息',
          key: 'sections',
          icon: <FolderOutlined />,
          children: [
            { title: '截面1', key: 'section-1', icon: <FileOutlined />, isLeaf: true },
            { title: '截面2', key: 'section-2', icon: <FileOutlined />, isLeaf: true },
          ]
        },
        {
          title: '放置点',
          key: 'placement-points',
          icon: <FolderOutlined />,
          children: [
            { title: '放置点1', key: 'point-1', icon: <FileOutlined />, isLeaf: true },
            { title: '放置点2', key: 'point-2', icon: <FileOutlined />, isLeaf: true },
          ]
        }
      ]
    }
  ];

  // 添加判断是否为箱梁节点的函数
  const isBoxGirderNode = (key: string) => {
    return key === 'box-girder' || key.includes('box-girder');
  };

  // 修改判断函数名称和逻辑
  const isLeafNode = (key: string) => {
    // 如果是箱梁节点，直接返回 true
    if (isBoxGirderNode(key)) return true;

    // 在 componentData 中查找对应的节点
    const findNode = (nodes: ComponentNode[]): ComponentNode | null => {
      for (const node of nodes) {
        if (node.key === key) return node;
        if (node.children) {
          const found = findNode(node.children);
          if (found) return found;
        }
      }
      return null;
    };

    const node = findNode(componentData);
    if (!node) return false;

    // 检查是否是叶子节点的父节点（即所有子节点都是叶子节点）
    if (node.children) {
      return node.children.every(child => child.isLeaf);
    }

    return false;
  };

  // 处理节点展开/收起
  const handleExpand = (expandedKeys: Key[], info: { 
    node: DataNode; 
    expanded: boolean; 
    nativeEvent: MouseEvent; 
  }) => {
    setExpandedKeys(expandedKeys);
  };

  // 修改渲染构件列表标题的函数
  const renderComponentListHeader = () => {
    return (
      <div className="section-header">
        <span>构件列表</span>
        <div className="header-actions">
          {selectedComponent && (
            <>
              <Button
                type="link"
                icon={<EditOutlined />}
                size="small"
                onClick={() => {
                  toggleGeometryPanel(true);
                  message.info('编辑构件');
                }}
              />
              <Button
                type="link"
                icon={<DeleteOutlined />}
                size="small"
                onClick={() => {
                  message.info('删除构件');
                }}
              />
            </>
          )}
          {selectedCategory && isLeafNode(selectedCategory) && (
            <Button 
              type="link" 
              icon={<PlusOutlined />} 
              size="small"
              onClick={() => setNewComponentModalVisible(true)}
            />
          )}
        </div>
      </div>
    );
  };

  // 修改处理节点选择的函数
  const handleSelect = (selectedKeys: Key[], info: { 
    node: DataNode; 
    selected: boolean; 
    selectedNodes: DataNode[]; 
  }) => {
    const key = selectedKeys[0] as string;
    
    if (info.node && (info.node as any).isLeaf) {
      // 如果是叶子节点，设置为选中的构件
      setSelectedComponent(key);
    } else if (isBoxGirderNode(key)) {
      setSelectedComponent(key);
      setSelectedCategory('box-girder');
    } else {
      setSelectedCategory(key);
      // 确保选中的节点被展开
      if (!expandedKeys.includes(key)) {
        setExpandedKeys([...expandedKeys, key]);
      }
    }

    // 如果选中的是最终子节点的父节点，显示构件列表
    if (isLeafNode(key)) {
      setSelectedCategory(key);
    }
  };

  // 修改渲染构件卡片函数
  const renderComponentCards = (node: ComponentNode) => {
    // 修改判断条件，只在选中叶子节点或箱梁节点时显示构件
    if (!isLeafNode(selectedCategory)) return null;
    if (node.key !== selectedCategory && selectedCategory !== 'box-girder') return null;

    // 如果是箱梁类别，直接返回箱梁示例构件
    if (selectedCategory === 'box-girder') {
      const boxGirderComponents = exampleComponents['box-girder'] || [];
      if (!boxGirderComponents.length) return null;

      return (
        <div className="component-cards">
          {boxGirderComponents.map(component => (
            <Card
              key={component.key}
              hoverable
              className={`component-card ${selectedComponent === component.key ? 'selected' : ''}`}
              onClick={() => setSelectedComponent(component.key)}
            >
              <div className="preview-area">
                {component.icon}
              </div>
              <div className="component-title">
                {component.title}
              </div>
            </Card>
          ))}
        </div>
      );
    }

    // 获取示例构件
    const examples = exampleComponents[node.key] || [];
    
    // 获取实际构件
    const getAllLeafNodes = (node: ComponentNode): ComponentNode[] => {
      let leaves: ComponentNode[] = [];
      if (node.children) {
        node.children.forEach(child => {
          if (child.isLeaf) {
            leaves.push(child);
          } else {
            leaves = leaves.concat(getAllLeafNodes(child));
          }
        });
      }
      return leaves;
    };

    const actualComponents = getAllLeafNodes(node);
    
    // 合并示例构件和实际构件
    const allComponents = [...examples, ...actualComponents];
    if (!allComponents.length) return null;

    return (
      <div className="component-cards">
        {allComponents.map(component => (
          <Card
            key={component.key}
            hoverable
            className={`component-card ${selectedComponent === component.key ? 'selected' : ''} ${
              component.key.startsWith('example-') ? 'example-card' : ''
            }`}
            onClick={() => {
              setSelectedComponent(component.key);
              message.success(`已选择构件: ${component.title}`);
            }}
          >
            <div className="preview-area">
              {component.icon}
              {component.key.startsWith('example-') && (
                <div className="example-tag">示例</div>
              )}
            </div>
            <div className="component-title">
              {component.title}
            </div>
          </Card>
        ))}
      </div>
    );
  };

  // 添加处理新建构件的函数
  const handleNewComponent = () => {
    form.validateFields().then(values => {
      message.success('构件创建成功！');
      setNewComponentModalVisible(false);
      form.resetFields();
      // 这里可以添加实际的构件创建逻辑
    }).catch(info => {
      console.log('Validate Failed:', info);
    });
  };

  // 修改处理宽度调整的函数
  const onResize = (event: React.SyntheticEvent, { size }: { size: { width: number; height: number } }) => {
    setSiderWidth(size.width);
    if (!showGeometryPanel) {
      setOriginalSiderWidth(size.width);
    }
  };

  // 添加处理类别区域高度调整的函数
  const handleCategoryResize = (e: React.SyntheticEvent, { size }: { size: { width: number; height: number } }) => {
    setCategoryHeight(size.height);
  };

  // 修改几何信息面板的显示/隐藏处理
  const toggleGeometryPanel = (show: boolean) => {
    if (show) {
      setOriginalSiderWidth(siderWidth); // 保存当前宽度
      setSiderWidth(siderWidth + 300); // 增加几何面板的宽度
    } else {
      setSiderWidth(originalSiderWidth); // 恢复原来的宽度
    }
    setShowGeometryPanel(show);
  };

  // 添加类别操作处理函数
  const handleAddCategory = () => {
    message.info('新建类别');
  };

  const handleEditCategory = () => {
    message.info('编辑类别');
  };

  const handleDeleteCategory = () => {
    message.info('删除类别');
  };

  return (
    <Layout className="component-detail">
      <Header className="header">
        <div className="header-left">
          <Button 
            type="link" 
            icon={<ArrowLeftOutlined />}
            onClick={() => navigate(-1)}
            className="back-button"
          >
            返回
          </Button>
          <span className="logo">构件库详情</span>
        </div>
      </Header>
      <Layout>
        <Resizable
          width={siderWidth}
          height={0}
          onResize={onResize}
          draggableOpts={{ enableUserSelectHack: false }}
          minConstraints={[250, 0]}
          maxConstraints={[900, 0]}
          handle={<div className="resize-handle" />}
        >
          <div className="component-sider" style={{ width: siderWidth }}>
            <div className="sider-content">
              <Resizable
                width={Infinity}
                height={categoryHeight}
                onResize={handleCategoryResize}
                axis="y"
                minConstraints={[0, 200]}
                maxConstraints={[0, 600]}
                handle={<div className="resize-handle-horizontal" />}
              >
                <div className="category-section" style={{ height: categoryHeight }}>
                  <div className="section-header">
                    <span>构件类别</span>
                    <div className="header-actions">
                      <Button
                        type="link"
                        icon={<PlusOutlined />}
                        size="small"
                        onClick={handleAddCategory}
                      />
                      <Button
                        type="link"
                        icon={<EditOutlined />}
                        size="small"
                        onClick={handleEditCategory}
                      />
                      <Button
                        type="link"
                        icon={<DeleteOutlined />}
                        size="small"
                        onClick={handleDeleteCategory}
                      />
                    </div>
                  </div>
                  <Tree
                    showIcon
                    expandedKeys={expandedKeys}
                    onExpand={handleExpand}
                    onSelect={handleSelect}
                    treeData={componentData}
                  />
                </div>
              </Resizable>
              <div className="component-section">
                {renderComponentListHeader()}
                <div className="component-list">
                  {componentData.map(category => renderComponentCards(category))}
                </div>
              </div>
            </div>
            {showGeometryPanel && (
              <div className="geometry-panel">
                <div className="panel-content">
                  <div className="section-header">
                    <span>几何信息</span>
                    <Button
                      type="link"
                      icon={<CloseOutlined />}
                      size="small"
                      onClick={() => toggleGeometryPanel(false)}
                    />
                  </div>
                  <Tree
                    showIcon
                    defaultExpandAll
                    treeData={geometryData}
                  />
                </div>
                <div className="preview-section">
                  <MiniModelViewer componentKey={selectedComponent} />
                </div>
              </div>
            )}
          </div>
        </Resizable>
        <Content className="preview-area">
          <ModelViewer componentKey={selectedComponent} />
        </Content>
      </Layout>

      {/* 新建构件弹窗 */}
      <Modal
        title="新建构件"
        open={newComponentModalVisible}
        onOk={handleNewComponent}
        onCancel={() => setNewComponentModalVisible(false)}
        okText="创建"
        cancelText="取消"
      >
        <Form form={form} layout="vertical">
          <Form.Item
            name="name"
            label="构件名称"
            rules={[{ required: true, message: '请输入构件名称' }]}
          >
            <Input placeholder="请输入构件名称" />
          </Form.Item>
          <Form.Item
            name="sectionSpacing"
            label="截面间距"
            rules={[{ required: true, message: '请输入截面间距' }]}
          >
            <Slider min={1} max={100} />
          </Form.Item>
          <Form.Item
            name="transitionType"
            label="过渡方式"
            rules={[{ required: true, message: '请选择过渡方式' }]}
          >
            <Select placeholder="请选择过渡方式">
              <Option value="linear">线性</Option>
              <Option value="smooth">平滑</Option>
            </Select>
          </Form.Item>
        </Form>
      </Modal>
    </Layout>
  );
};

export default ComponentDetail; 