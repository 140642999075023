import React from 'react';
import './style.less';

interface MiniModelViewerProps {
  componentKey?: string;
}

const MiniModelViewer: React.FC<MiniModelViewerProps> = ({ componentKey }) => {
  return (
    <div className="mini-model-viewer">
      <div className="model-container">
        <div className="model-placeholder">
          <div className="model-info">
            <div className="model-title">构件预览</div>
            <div className="model-key">{componentKey}</div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default MiniModelViewer; 