import React from 'react';
import './style.less';

interface ModelViewerProps {
  componentKey?: string;
}

const ModelViewer: React.FC<ModelViewerProps> = ({ componentKey }) => {
  return (
    <div className="model-viewer">
      <div className="model-container">
        {componentKey ? (
          <div className="model-content">
            <div className="model-placeholder">
              <div className="model-info">
                <div className="model-title">构件三维模型预览</div>
                <div className="model-key">当前构件: {componentKey}</div>
              </div>
            </div>
          </div>
        ) : (
          <div className="empty-placeholder">
            请选择构件以预览三维模型
          </div>
        )}
      </div>
    </div>
  );
};

export default ModelViewer; 